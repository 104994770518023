.feature-section {
    padding: 40px 0px;
    overflow: hidden;
    background-color: #f8f9fa; /* Light background to make content stand out */
}

.feature-section .slick-active {
    border: none !important;
}

.feature-section .card {
    border: none !important;
    min-height: 350px; /* Set minimum height for the card to ensure consistency across different cards */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures content is aligned within the card */
    text-align: center;
    padding: 20px 10px;
    margin-bottom: 30px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    background-color: #ffffff; /* White background for the card */
}

.feature-section .card:hover {
    transform: translateY(-10px); /* Subtle hover effect */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.feature-section .card img.feature-img {
    width: 100%; /* Full width of the parent card */
    height: 200px; /* Fixed height to maintain consistency */
    object-fit: cover; /* Ensures the image covers the area while maintaining aspect ratio */
    border-radius: 10px; /* Adds rounded corners */
    margin-bottom: 15px;
}

.feature-section .card .card-title {
    font-size: var(--subTitle);
    padding: 12px 0px;
    font-weight: bold;
    color: #333; /* Darker text for better readability */
}

.feature-section .card .card-text {
    font-size: var(--bodyText);
    line-height: 1.6;
    color: #666; /* Softer text color */
}

.feature-section .card:hover .card-title {
    color: var(--primaryClr);
}

@media (max-width: 768px) {
    .feature-section .card {
        margin-bottom: 20px;
    }
    .feature-section .card img.feature-img {
        height: 180px; /* Smaller height for mobile */
    }
}
