.aboutPage_container {
    font-family: 'Arial', sans-serif;
    color: #333;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }
  
  .aboutPage_banner {
    background-image: url('../../assets/images/slider/1.webp'); /* Replace with your image URL */
    background-size: cover;
    background-position: center;
    margin-top: 4rem;
    padding: 60px 20px;
    text-align: center;
    color: #fff;
  }
  
  .aboutPage_title {
    font-size: 48px;
    margin-bottom: 10px;
  }
  
  .aboutPage_subtitle {
    font-size: 24px;
    margin: 0;
    color: white;
  }
  
  .aboutPage_content {
    padding: 40px 20px;
  }
  
  .aboutPage_section {
    margin-bottom: 40px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .aboutPage_heading {
    font-size: 32px;
    margin-bottom: 15px;
    color: #004085;
  }
  
  .aboutPage_paragraph {
    font-size: 18px;
    line-height: 1.6;
  }
  
  .aboutPage_list {
    padding: 0;
    list-style-type: none;
  }
  
  .aboutPage_listItem {
    font-size: 18px;
    margin: 10px 0;
    padding-left: 20px;
    position: relative;
  }
  
  .aboutPage_listItem::before {
    content: '✔';
    color: #004085;
    position: absolute;
    left: 0;
    top: 0;
  }
