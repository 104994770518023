.results-container {
    margin-top: 80px;
    padding: 20px;
  }
  
  .results-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
  }
  
  .tour-card {
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .tour-card:hover {
    transform: translateY(-5px);
  }
  
  .tour-image {
    height: 200px;
    object-fit: cover;
  }
  
  .tour-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007bff;
  }
  
  .tour-desc {
    font-size: 0.9rem;
    color: #555;
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .tour-details {
    margin-top: 10px;
  }
  
  .tour-price,
  .tour-city,
  .tour-group-size {
    font-size: 0.95rem;
    color: #333;
  }
  
  .tour-btn {
    width: 100%;
    background-color: #007bff;
    border: none;
    padding: 10px;
  }
  
  .tour-btn:hover {
    background-color: #0056b3;
  }
  
  .no-results {
    text-align: center;
    font-size: 1.2rem;
    color: #666;
    margin-top: 20px;
  }
  
  .booking-form-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    position: sticky;
    top: 20px;
  }
  
  .booking-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-control {
    padding: 10px;
    font-size: 1rem;
  }
  
  button[type="submit"] {
    font-size: 1.1rem;
    padding: 10px;
    background-color: #28a745;
  }
  
  button[type="submit"]:hover {
    background-color: #218838;
  }
  
  @media (max-width: 768px) {
    .results-container {
      margin-top: 15px;
      padding: 10px;
    }
  
    .tour-title {
      font-size: 1.25rem;
    }
  
    .booking-form-container {
      margin-top: 20px;
    }
  }
  