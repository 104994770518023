.tour-details-container {
  margin-top: 150px;
  margin-bottom: 50px;
  font-family: "Arial", sans-serif;
}

.tour-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.tour-details-content {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.tour-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}

.tour-desc {
  margin-top: 15px;
  font-size: 1.2rem;
  color: #666;
  word-wrap: break-word;
  white-space: normal;
  overflow: visible; /* Ensure the container adjusts to content */
  line-height: 1.5; /* Increase line spacing */
  display: block;
  min-height: auto; /* Let the height adjust automatically */
  height: auto; /* Let height expand with content */
}



.tour-info {
  margin-top: 10px;
  font-size: 1.1rem;
}

.tour-price {
  font-size: 1.4rem;
  color: #28a745;
  font-weight: bold;
}

.booking-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.book-btn {
  font-size: 1.2rem;
  padding: 12px;
  background-color: #25d366;
  border: none;
}

.book-btn:hover {
  background-color: #218838;
  transition: 0.3s ease;
}

.loading-section {
  text-align: center;
  margin-top: 50px;
}

.loading-text {
  font-size: 1.2rem;
  color: #999;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .tour-title {
    font-size: 2rem;
  }

  .tour-desc {
    font-size: 1rem;
  }

  .booking-section {
    padding: 15px;
  }

  .book-btn {
    font-size: 1.1rem;
    padding: 10px;
  }
}
