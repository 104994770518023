.upload-tour-container {
    margin-top: 80px;
    margin-bottom: 50px;
  }
  
  .upload-title, .delete-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .form-control {
    border-radius: 5px;
  }
  
  .submit-btn {
    background-color: #28a745;
    border: none;
    padding: 10px 20px;
    font-size: 1.1rem;
    width: 100%;
    margin-top: 20px;
  }
  
  .submit-btn:hover {
    background-color: #218838;
    transition: 0.3s ease;
  }
  
  .delete-title {
    margin-top: 40px;
    color: #d9534f;
  }
  
  .delete-btn {
    background-color: #d9534f;
    border: none;
    padding: 10px 20px;
    font-size: 1.1rem;
    width: 100%;
    margin-top: 20px;
  }
  
  .delete-btn:hover {
    background-color: #c9302c;
    transition: 0.3s ease;
  }
  